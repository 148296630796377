import Q1o1 from "../assets/svg/optionSvgs/Q1o1";
import Q1o2 from "../assets/svg/optionSvgs/Q1o2";
import Q1o3 from "../assets/svg/optionSvgs/Q1o3";
import Q1o4 from "../assets/svg/optionSvgs/Q1o4";
import Q2o1 from "../assets/svg/optionSvgs/Q2o1";
import Q2o2 from "../assets/svg/optionSvgs/Q2o2";
import Q2o3 from "../assets/svg/optionSvgs/Q2o3";
import Q3o1 from "../assets/svg/optionSvgs/Q3o1";
import Q3o2 from "../assets/svg/optionSvgs/Q3o2";
import Q3o3 from "../assets/svg/optionSvgs/Q3o3";
import Q4o1 from "../assets/svg/optionSvgs/Q4o1";
import Q4o2 from "../assets/svg/optionSvgs/Q4o2";
import Q4o3 from "../assets/svg/optionSvgs/Q4o3";
import CreamDot from "../assets/svg/CreamDot";
import BlackDot from "../assets/svg/BlackDot";

const questions = [
  {
    question: "What can we call you?",
    options: [],
    type: "input",
    placeholder: "Enter your name",
  },
  {
    question: "Let’s begin, which one of these describes you best?",
    type: "radio",
    options: [
      {
        value: "A",
        label: "I have cared for someone in my family",
        image: Q1o1,
        id: "Q1o1",
      },
      {
        value: "B",
        label: "I have cared for someone who feels like family",
        image: Q1o2,
        id: "Q1o2",
      },
      {
        value: "C",
        label:
          "I have not been a primary caregiver but I have supported the act of caregiving",
        image: Q1o3,
        id: "Q1o3",
      },
      {
        value: "D",
        label:
          "I have no experience in giving or receiving care but it is likely that I will at some point",
        image: Q1o4,
        id: "Q1o4",
      },
    ],
  },
  {
    question:
      "Great!  Let’s talk about attributes, which caregiver trait do you resonate with the most?",
    type: "radio",
    options: [
      {
        value: "A",
        label:
          "I'm a problem solver. I keep myself well informed and make decisions best suited for the one I'm caring for.",
        image: Q2o1,
        id: "Q2o1",
      },
      {
        value: "B",
        label:
          "I'm the practical helper who is attentive to the one I'm caring for and do the needful.",
        image: Q2o2,
        id: "Q2o2",
      },
      {
        value: "C",
        label:
          "I'm an emotional supporter. I try to keep spirits up and encourage the one I care for. After all, it's mind over matter.",
        image: Q2o3,
        id: "Q2o3",
      },
    ],
  },
  {
    question:
      "When the going gets tough or situations become  tense regarding the health of someone you care for, how would you react?",
    type: "radio",
    options: [
      {
        value: "A",
        label: "I'm calm and unwavering",
        image: Q3o1,
        id: "Q3o1",
      },
      {
        value: "B",
        label:
          "I'm nervous but I will stay strong for the person I'm caring for",
        image: Q3o2,
        id: "Q3o2",
      },
      {
        value: "C",
        label:
          "I will feel for the person I care for and their distress is mine",
        image: Q3o3,
        id: "Q3o3",
      },
    ],
  },
  {
    question: "What do you think motivates someone to become a caregiver ?",
    type: "checkbox",
    options: [
      {
        value: "A",
        label: "Family obligations and a sense of responsibility",
        image: Q4o1,
        id: "Q4o1",
      },
      {
        value: "B",
        label:
          "Feeling of goodness or fulfillment from the act of caring and being of help",
        image: Q4o2,
        id: "Q4o2",
      },
      {
        value: "C",
        label: "A sense of being acknowledged",
        image: Q4o3,
        id: "Q4o3",
      },
      {
        value: "D",
        label: "Situational (nobody else was there)",
        id: "Q4o4",
      },
    ],
  },
  {
    question: "What are the more fulfilling parts of caregiving? ",
    type: "checkbox",
    options: [
      {
        value: "A",
        label: "Compassion - the act of giving or receiving care",
        image: CreamDot,
        id: "Lg1",
      },
      {
        value: "B",
        label: "Relief when the patient feels good",
        image: CreamDot,
        id: "Lg2",
      },
      {
        value: "C",
        label:
          "Giving back - karma (taking care of someone who has cared for you)",

        image: CreamDot,
        id: "Lg3",
      },
      {
        value: "D",
        label: "Acknowledgement and being appreciated",
        image: CreamDot,
        id: "Lg4",
      },
    ],
  },
  {
    question: "What are the more difficult parts of caregiving? ",
    type: "checkbox",
    options: [
      {
        value: "A",
        label: "Life altering - no sense of normalcy, delay in life plans",
        image: BlackDot,
        id: "Dg1",
      },
      {
        value: "B",
        label: "Emotional wellbeing severely affected",
        image: BlackDot,
        id: "Dg2",
      },
      {
        value: "C",
        label: "Halt or delay in professional/personal growth",
        image: BlackDot,
        id: "Dg3",
      },
      {
        value: "D",
        label: "Not having control over a loved one’s wellbeing",
        image: BlackDot,
        id: "Dg4",
      },
    ],
  },
  {
    question:
      "Would you want to share a short but significant caregiving moment with us?",
    options: [],
    type: "input",
  },
  {
    question:
      "Please leave your email address if you would like to receive your personalised tile/ unique caregiving style via email / if you would like to share  more about your caregiving experience",
    options: [],
    type: "input",
  },
];

export default questions;
