import React from "react";

const Q41 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="277"
        // height="277"
        className="w-[277px] h-[277px] lg:w-[127px] lg:h-[124px]"
        viewBox="0 0 277 277"
        fill="none"
      >
        <path
          d="M277 273.297V9.15527e-05H182.785V185.081L0.243164 183.205V276.757L277 273.297Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q41;
