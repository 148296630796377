import React from 'react'

const Q1o1 = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M28.9362 66.7154C34.0426 39.5769 49.3617 28.2692 80 27.7039L80 -1.27115e-06C34.6099 0.565375 8.51065 23.1808 1.70214 66.7154C0.567384 74.0654 9.19351e-06 94.4192 9.19351e-06 94.4192L8.89695e-06 96.1154L0 147L27.234 147L27.234 94.4192C27.234 94.4192 27.2341 74.0654 28.9362 66.7154Z"
          fill="#ED2777"
        />
      </svg>
    </div>
  );
}

export default Q1o1
