import React from "react";

const M13 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path d="M0 0V80H27.234V27.0423H80V0H0Z" fill="white" />
        <path
          d="M0 0V80H27.234V27.0423H80V0H0Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M13;
