import React from 'react'

const M32 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M51.0638 66.7155C45.9574 39.577 30.6383 28.2693 2.02978e-06 27.7039L-2.81412e-06 5.9764e-05C45.3901 0.565436 71.4893 23.1808 78.2979 66.7155C79.4326 74.0655 80 94.4193 80 94.4193L80 96.1154L80 147L52.766 147L52.766 94.4193C52.766 94.4193 52.7659 74.0655 51.0638 66.7155Z"
          fill="white"
        />
        <path
          d="M51.0638 66.7155C45.9574 39.577 30.6383 28.2693 2.02978e-06 27.7039L-2.81412e-06 5.9764e-05C45.3901 0.565436 71.4893 23.1808 78.2979 66.7155C79.4326 74.0655 80 94.4193 80 94.4193L80 96.1154L80 147L52.766 147L52.766 94.4193C52.766 94.4193 52.7659 74.0655 51.0638 66.7155Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M32
