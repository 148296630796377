import React from "react";

const M31 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path d="M80 0V80H52.766V27.0423H0V0H80Z" fill="white" />
        <path
          d="M80 0V80H52.766V27.0423H0V0H80Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M31;
