import React from "react";

const Q42 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="277"
        // height="516"
        className="w-[277px] h-[516px] lg:w-[127px] lg:h-[234px]"
        viewBox="0 0 277 516"
        fill="none"
      >
        <path
          d="M176.808 281.815C159.128 377.077 106.085 416.769 1.83355e-05 418.754L1.33242e-06 516C157.163 514.015 247.532 434.631 271.106 281.815C275.035 256.015 277 184.569 277 184.569L277 178.615L277 0L182.702 -1.64876e-05L182.702 184.569C182.702 184.569 182.702 256.015 176.808 281.815Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q42;
