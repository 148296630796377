import React from 'react'

const BottomRight = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
      >
        <path
          opacity="0.16"
          d="M75.687 72.0062C75.687 72.6216 75.687 73.2369 75.0717 74.4676C74.4563 74.4676 73.2256 74.4676 72.6103 75.0829C24.6137 82.467 0.615341 110.773 0 160H30.1517C30.7671 127.387 43.0739 110.157 72.6103 104.619H73.2256C81.8404 102.773 102.762 102.773 102.762 102.773C102.762 102.773 102.762 81.8517 104.608 73.2369V72.6216C110.146 43.0852 126.76 30.7783 159.989 30.163V0.0112596C111.377 -0.604082 83.0711 24.0096 75.687 72.0062Z"
          fill="#FAA31B"
        />
      </svg>
    </div>
  );
}

export default BottomRight
