import React from 'react'

const BottomLeft = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
      >
        <path
          opacity="0.16"
          d="M84.313 72.0062C84.313 72.6216 84.313 73.2369 84.9283 74.4676C85.5437 74.4676 86.7744 74.4676 87.3897 75.0829C135.386 82.467 159.385 110.773 160 160H129.848C129.233 127.387 116.926 110.157 87.3897 104.619H86.7744C78.1596 102.773 57.238 102.773 57.238 102.773C57.238 102.773 57.238 81.8517 55.392 73.2369V72.6216C49.8539 43.0852 33.2397 30.7783 0.011261 30.163V0.0112596C48.6232 -0.604082 76.9289 24.0096 84.313 72.0062Z"
          fill="#00B9EA"
        />
      </svg>
    </div>
  );
}

export default BottomLeft
