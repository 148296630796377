import React from 'react'

const IntroLogo = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="322"
        height="321"
        viewBox="0 0 322 321"
        fill="none"
      >
        <path
          d="M254.116 223.469H253.326V172.685H226.298V225.219H173.707V252.586H224.492C224.523 252.848 224.523 253.113 224.492 253.376C219.357 280.461 203.896 291.859 173.707 292.254V320.073C218.849 319.565 244.919 297.107 251.577 253.319C251.615 252.586 251.615 251.852 251.577 251.119H253.778C297.565 244.46 320.024 218.391 320.588 173.249H292.938C292.543 203.268 281.145 218.729 254.06 224.034"
          fill="#FAA31B"
        />
        <path
          d="M226.298 94.589V147.405H253.439V96.6204H254.229C281.314 101.699 292.713 117.16 293.108 147.405H321.152C320.588 102.263 298.13 76.1936 254.342 69.5352L252.141 69.1966C252.18 68.4824 252.18 67.7666 252.141 67.0524C245.483 23.2647 219.413 0.750156 174.271 0.24231V27.9482C204.291 28.2867 219.752 39.6851 225.056 66.8267C225.088 67.0891 225.088 67.3543 225.056 67.6167H174.271V94.589H226.298Z"
          fill="#00986B"
        />
        <path
          d="M95.4423 225.444V172.854H68.3572V223.638H67.6236C40.482 218.504 29.0837 203.042 28.6887 172.854H0.926392C1.43424 217.996 23.9488 244.065 67.7365 250.724H69.8807C69.8807 251.514 69.8807 252.304 70.2193 252.924C76.8777 296.712 102.947 319.17 148.033 319.678V292.085C118.013 291.69 102.552 280.292 97.248 253.206V252.416H148.033V225.444H95.4423Z"
          fill="#00B9EA"
        />
        <path
          d="M148.033 27.9481V0.072998C102.891 0.580845 76.8777 23.0954 70.2193 66.8831C70.2193 67.5038 70.2193 68.2373 69.8807 69.0273L67.7365 69.3659C23.9488 76.0243 1.43424 102.094 0.926392 147.236H28.7451C29.1401 117.16 40.5385 101.699 67.68 96.451H68.4136V147.236H95.4423V94.5889H147.976V67.6166H97.1916V66.8266C102.27 39.6851 117.731 28.2867 147.976 27.9481"
          fill="#ED2777"
        />
        <path
          d="M70.2193 66.8831C70.2193 67.5038 70.2193 68.2373 69.8807 69.0273L67.7365 69.3659C23.9488 76.0243 1.43424 102.094 0.926392 147.236H28.7451C29.1401 117.16 40.5385 101.699 67.68 96.451H68.4136C77.3794 95.3066 86.4043 94.6848 95.4423 94.5889C95.5173 85.5331 96.1203 76.4894 97.248 67.5038V66.7702C102.326 39.6286 117.788 28.2303 148.033 27.8917V0.072998C102.891 0.580845 76.8777 23.0954 70.2193 66.8831Z"
          fill="#ED2777"
        />
        <path
          d="M97.4737 253.263C102.552 280.348 118.013 291.746 148.258 292.141V319.96C103.116 319.452 77.047 296.994 70.3886 253.206C69.0343 245.702 68.5829 225.444 68.5829 225.444V172.854H95.4423V225.444C95.4876 234.75 96.1473 244.044 97.4173 253.263"
          fill="#00B9EA"
        />
        <path
          d="M254.116 223.469C281.201 218.334 292.6 202.873 292.995 172.685H321.152C320.588 217.827 298.13 243.896 254.342 250.554C246.837 251.909 226.58 252.304 226.58 252.304H173.707V225.444H226.298C235.604 225.399 244.897 224.739 254.116 223.469Z"
          fill="#FAA31B"
        />
        <path
          d="M253.439 96.5075V147.292H226.354V94.5889H173.707V67.5038H253.439"
          fill="#00986B"
        />
      </svg>
    </div>
  );
}

export default IntroLogo
