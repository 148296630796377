import React from "react";

const Q22 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="512"
        // height="509"
        className="w-[512px] h-[509px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 512 509"
        fill="none"
      >
        <path
          d="M228.863 268.421C230.819 268.421 232.774 268.421 236.686 270.377C236.686 272.333 236.686 276.245 238.642 278.201C262.111 430.763 355.537 507.044 512 509L512 413.16C408.343 411.204 350.122 372.085 332.52 278.201L332.52 276.245C326.652 248.862 326.652 182.36 326.652 182.36C326.652 182.36 260.156 182.36 232.775 176.493L230.819 176.493C136.941 158.889 97.8252 106.079 95.8694 0.459468L0.035765 0.459473C-1.92002 154.978 76.3116 244.95 228.863 268.421Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q22;
