import React from "react";
import M11 from "../assets/svg/finalLogoSvgs/M11.js";
import M12 from "../assets/svg/finalLogoSvgs/M12.js";
import M13 from "../assets/svg/finalLogoSvgs/M13.js";
import M14 from "../assets/svg/finalLogoSvgs/M14.js";
import M21 from "../assets/svg/finalLogoSvgs/M21.js";
import M22 from "../assets/svg/finalLogoSvgs/M22.js";
import M23 from "../assets/svg/finalLogoSvgs/M23.js";
import M31 from "../assets/svg/finalLogoSvgs/M31.js";
import M32 from "../assets/svg/finalLogoSvgs/M32.js";
import M33 from "../assets/svg/finalLogoSvgs/M33.js";
import M41 from "../assets/svg/finalLogoSvgs/M41.js";
import M42 from "../assets/svg/finalLogoSvgs/M42.js";
import M43 from "../assets/svg/finalLogoSvgs/M43.js";

const MainLogo = ({ id }) => {
  return (
    <div className="flex gap-5 h-[350px] relative">
      <div>
        {/* 1 */}
        <div className="relative h-[147px] w-[147px]">
          <div className="w-20 absolute">
            <div className="ps-5 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[5]?.includes("Lg1") ? "#AFAFAF" : "#F5F5F5"}
                />
              </svg>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[6]?.includes("Dg1") ? "#383838" : "#F5F5F5"}
                />
              </svg>
            </div>
          </div>
          <div className={`absolute right-0 ${id[1] === "Q1o1" ? "z-10" : ""}`}>
            <M11 color="#ED2777" flag={id[1] === "Q1o1"} />
          </div>
          <div
            className={`absolute top-0 right-0 ${
              id[1] === "Q1o2" ? "z-10" : ""
            }`}
          >
            <M12 color="#ED2777" flag={id[1] === "Q1o2"} />
          </div>
          <div className="absolute top-[68px] right-0">
            <M13 color="#ED2777" flag={id[1] === "Q1o3"} />
          </div>
          <div className="absolute top-[69px] right-[78px]">
            <M14 color="#ED2777" flag={id[1] === "Q1o4"} />
          </div>
        </div>
        {/* 2 */}
        <div className="relative mt-5">
          <div className="w-20 absolute top-28">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[6]?.includes("Dg2") ? "#383838" : "#F5F5F5"}
                />
              </svg>
            </div>
            <div className="ps-5 mt-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[5]?.includes("Lg2") ? "#AFAFAF" : "#F5F5F5"}
                />
              </svg>
            </div>
          </div>
          <div className={`absolute right-0 ${id[2] === "Q2o1" ? "z-10" : ""}`}>
            <M21 color="#00B9EA" flag={id[2] === "Q2o1"} />
          </div>
          <div
            className={`absolute top-0 right-0 ${
              id[2] === "Q2o2" ? "z-10" : ""
            }`}
          >
            <M22 color="#00B9EA" flag={id[2] === "Q2o2"} />
          </div>
          <div className="absolute right-0 top-0">
            <M23 color="#00B9EA" flag={id[2] === "Q2o3"} />
          </div>
        </div>
      </div>
      <div>
        {/* 3 */}
        <div className="relative h-[147px] w-[147px]">
          <div className="w-14 absolute right-0">
            <div className="mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[5]?.includes("Lg3") ? "#AFAFAF" : "#F5F5F5"}
                />
              </svg>
            </div>
            <div className="ps-7">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[6]?.includes("Dg3") ? "#383838" : "#F5F5F5"}
                />
              </svg>
            </div>
          </div>
          <div
            className={`absolute bottom-0 ${id[3] === "Q3o1" ? "z-10" : ""}`}
          >
            <M31 color="#00986B" flag={id[3] === "Q3o1"} />
          </div>
          <div
            className={`absolute top-0 left-0  ${
              id[3] === "Q3o2" ? "z-10" : ""
            }`}
          >
            <M32 color="#00986B" flag={id[3] === "Q3o2"} />
          </div>
          <div className="absolute top-[68px] right-0">
            <M33 color="#00986B" flag={id[3] === "Q3o3"} />
          </div>
        </div>
        {/* 4 */}
        <div className="relative mt-5">
          <div className="w-12 absolute top-28 right-0">
            <div className="ps-5 mb-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[6]?.includes("Dg4") ? "#383838" : "#F5F5F5"}
                />
              </svg>
            </div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 15 15"
                fill="none"
              >
                <path
                  d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                  fill={id[5]?.includes("Lg4") ? "#AFAFAF" : "#F5F5F5"}
                />
              </svg>
            </div>
          </div>
          <div className={`absolute  ${id[4]?.includes("Q4o1") ? "z-10" : ""}`}>
            <M41 color="#FAA31B" flag={id[4]?.includes("Q4o1")} />
          </div>
          <div
            className={`absolute top-0 ${
              id[4]?.includes("Q4o2") ? "z-10" : ""
            }`}
          >
            <M42 color="#FAA31B" flag={id[4]?.includes("Q4o2")} />
          </div>
          <div
            className={`absolute top-0 ${
              id[4]?.includes("Q4o3") ? "z-10" : ""
            }`}
          >
            <M43 color="#FAA31B" flag={id[4]?.includes("Q4o3")} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainLogo;
