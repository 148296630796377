import React from "react";

const M12 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147"
        height="147"
        viewBox="0 0 147 147"
        fill="none"
      >
        <path
          d="M69.5423 66.1557C69.5423 66.7211 69.5423 67.2864 68.977 68.4171C68.4116 68.4171 67.2808 68.4171 66.7154 68.9824C22.6154 75.7666 0.565385 101.772 0 147H27.7039C28.2692 117.037 39.5769 101.207 66.7154 96.119H67.2808C75.1962 94.4229 94.4193 94.423 94.4193 94.423C94.4193 94.423 94.4192 75.2012 96.1154 67.2864V66.7211C101.204 39.5845 116.469 28.2776 147 27.7122V0.0103447C102.335 -0.555 76.3269 22.0588 69.5423 66.1557Z"
          fill="white"
        />
        <path
          d="M69.5423 66.1557C69.5423 66.7211 69.5423 67.2864 68.977 68.4171C68.4116 68.4171 67.2808 68.4171 66.7154 68.9824C22.6154 75.7666 0.565385 101.772 0 147H27.7039C28.2692 117.037 39.5769 101.207 66.7154 96.119H67.2808C75.1962 94.4229 94.4193 94.423 94.4193 94.423C94.4193 94.423 94.4192 75.2012 96.1154 67.2864V66.7211C101.204 39.5845 116.469 28.2776 147 27.7122V0.0103447C102.335 -0.555 76.3269 22.0588 69.5423 66.1557Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M12;
