import React from 'react'

const TopRight = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
      >
        <path
          opacity="0.16"
          d="M75.687 87.9938C75.687 87.3784 75.687 86.7631 75.0717 85.5324C74.4563 85.5324 73.2256 85.5324 72.6103 84.9171C24.6137 77.533 0.615341 49.2273 0 0H30.1517C30.7671 32.6131 43.0739 49.8426 72.6103 55.3807H73.2256C81.8404 57.2267 102.762 57.2267 102.762 57.2267C102.762 57.2267 102.762 78.1483 104.608 86.7631V87.3784C110.146 116.915 126.76 129.222 159.989 129.837V159.989C111.377 160.604 83.0711 135.99 75.687 87.9938Z"
          fill="#00986B"
        />
      </svg>
    </div>
  );
}

export default TopRight
