import React from "react";

const M14 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="78"
        viewBox="0 0 69 78"
        fill="none"
      >
        <path
          d="M0.184839 78H28.1848C25.3848 38.4 53.6848 27.5 68.1848 27V0C9.38484 4.8 -1.64849 54 0.184839 78Z"
          fill="white"
        />
        <path
          d="M0.184839 78H28.1848C25.3848 38.4 53.6848 27.5 68.1848 27V0C9.38484 4.8 -1.64849 54 0.184839 78Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M14;
