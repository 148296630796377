import React from "react";
import QuestionForm from "./components/QuestionForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import EmailForm from "./components/EmailForm";
import IntroPage from "./components/IntroPage";
import SubmitPage from "./components/SubmitPage";
import { Toaster } from "react-hot-toast";

const App = () => {
  return (
    <div>
      <Router>
        <Routes>
          {/* <Route path="/" element={<EmailForm />} />
          <Route path="/intro" element={<IntroPage />} /> */}
          <Route path="/" element={<IntroPage />} />
          <Route path="/form" element={<QuestionForm />} />
          <Route path="/submit" element={<SubmitPage />} />
        </Routes>
      </Router>
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default App;
