import React from "react";
import Q41 from "../../assets/svg/LogoSvg/Q41";
import Q42 from "../../assets/svg/LogoSvg/Q42";
import Q43 from "../../assets/svg/LogoSvg/Q43";

const Que4Logo = ({ id }) => {
  return (
    <div>
      <div className="">
        <div
          className={`absolute top-0 ${id[4]?.includes("Q4o1") ? "z-10" : ""}`}
        >
          <Q41
            color="#F5F5F5"
            SelectedColor="#FAA31B"
            flag={id[4]?.includes("Q4o1")}
          />
        </div>
        <div
          className={`absolute top-0 ${id[4]?.includes("Q4o2") ? "z-10" : ""}`}
        >
          <Q42
            color="#F5F5F5"
            SelectedColor="#FAA31B"
            flag={id[4]?.includes("Q4o2")}
          />
        </div>
        <div
          className={`absolute top-0 ${id[4]?.includes("Q4o3") ? "z-10" : ""}`}
        >
          <Q43
            color="#F5F5F5"
            SelectedColor="#FAA31B"
            flag={id[4]?.includes("Q4o3")}
          />
        </div>
      </div>
    </div>
  );
};

export default Que4Logo;
