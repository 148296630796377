import React from "react";

const Q13 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="277"
        // height="277"
        className="w-[277px] h-[277px] lg:w-[127px] lg:h-[127px]"
        viewBox="0 0 277 277"
        fill="none"
      >
        <path
          d="M0.243256 3.45946V276.757H94.4583V91.6757L277 93.5517V0L0.243256 3.45946Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q13;
