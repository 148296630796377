import React from "react";

const Q11 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="277"
        // height="516"
        className="w-[277px] h-[516px] lg:w-[127px] lg:h-[234px]"
        viewBox="0 0 277 516"
        fill="none"
      >
        <path
          d="M100.192 234.185C117.872 138.923 170.915 99.2308 277 97.2462L277 -1.2603e-05C119.837 1.98458 29.4681 81.3692 5.89365 234.185C1.96457 259.985 3.22711e-05 331.431 3.22711e-05 331.431L3.12301e-05 337.385L0 516L94.2978 516L94.2978 331.431C94.2978 331.431 94.2979 259.985 100.192 234.185Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q11;
