import React, { useState } from "react";
import { motion } from "framer-motion";
import { IoArrowBackOutline } from "react-icons/io5";
import questions from "../helper/data.js"; // Assume this imports your questions data
import Button from "./ui/Button.js";
import Que1Logo from "./quelogos/Que1Logo.js";
import Que2Logo from "./quelogos/Que2Logo.js";
import Que3Logo from "./quelogos/Que3Logo.js";
import Que4Logo from "./quelogos/Que4Logo.js";
import Que5Logo from "./quelogos/Que5Logo.js";
import Que6Logo from "./quelogos/Que6Logo.js";
import MainLogo from "./MainLogo.js";
import { useNavigate } from "react-router-dom";

const QuestionForm = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(
    questions.map((q) => ({ que: q.question, answer: "" }))
  );
  const [selectedIds, setSelectedIds] = useState(
    Array(questions.length).fill(null)
  );
  const [name, setName] = useState("");
  const [caregivingMoment, setCaregivingMoment] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleNext = () => {
    const selectedAnswer = answers[currentQuestionIndex].answer;

    if (currentQuestionIndex === 0 && !name.trim()) {
      setErrorMessage("Please enter your name.");
      return;
    }

    if (!selectedAnswer && currentQuestion.type !== "input") {
      setErrorMessage("Please select an option");
      return;
    }

    if (selectedAnswer || currentQuestionIndex === 0) {
      setErrorMessage("");
    }

    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      console.log("Final Answers:", answers);
      console.log("Selected IDs:", selectedIds);
      if (emailInput) {
      } else {
        navigate("/submit", {
          state: {
            answers,
            selectedIds,
          },
        });
      }
    }
  };

  const handleBack = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setErrorMessage("");
    }
  };

  const handleOptionChange = (value, id) => {
    const updatedAnswers = [...answers];
    const currentQuestion = questions[currentQuestionIndex];

    // Find the corresponding label for the selected value
    const selectedOption = currentQuestion.options.find(
      (option) => option.value === value
    );
    const selectedLabel = selectedOption ? selectedOption.label : "";

    // Multi-select logic for checkboxes
    if (currentQuestion.type === "checkbox") {
      if (!Array.isArray(updatedAnswers[currentQuestionIndex].answer)) {
        updatedAnswers[currentQuestionIndex].answer = [];
      }

      // If the option is already selected, remove it; otherwise, add it
      if (updatedAnswers[currentQuestionIndex].answer.includes(selectedLabel)) {
        updatedAnswers[currentQuestionIndex].answer = updatedAnswers[
          currentQuestionIndex
        ].answer.filter((selectedValue) => selectedValue !== selectedLabel);
        setSelectedIds((prevIds) => {
          const newIds = [...prevIds];
          newIds[currentQuestionIndex] = newIds[currentQuestionIndex].filter(
            (selectedId) => selectedId !== id
          );
          return newIds;
        });
      } else {
        updatedAnswers[currentQuestionIndex].answer.push(selectedLabel);
        setSelectedIds((prevIds) => {
          const newIds = [...prevIds];
          newIds[currentQuestionIndex] = [
            ...(newIds[currentQuestionIndex] || []),
            id,
          ];
          return newIds;
        });
      }
    } else {
      // Single selection (radio buttons)
      updatedAnswers[currentQuestionIndex].answer = [selectedLabel]; // Ensure it's always an array
      setSelectedIds((prevIds) => {
        const newIds = [...prevIds];
        newIds[currentQuestionIndex] = id;
        return newIds;
      });
    }

    setAnswers(updatedAnswers);
    setErrorMessage("");
  };

  const handleNameChange = (e) => {
    const nameValue = e.target.value;
    setName(nameValue);
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[0].answer = [nameValue]; // Wrap the name in an array
      return updatedAnswers;
    });
    setErrorMessage("");
  };

  const handleCaregivingMomentChange = (e) => {
    const caregivingMomentValue = e.target.value;
    setCaregivingMoment(caregivingMomentValue);
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[7].answer = [caregivingMomentValue]; // Wrap the caregiving moment in an array
      return updatedAnswers;
    });
    setErrorMessage("");
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailInput(email);
    setAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[8].answer = [email]; // Wrap the email in an array
      return updatedAnswers;
    });
    setErrorMessage("");
  };

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="flex h-screen">
      <div
        className={`bg-white w-full flex justify-between gap-20 lg:gap-1 ${
          currentQuestionIndex === 5 ||
          currentQuestionIndex === 6 ||
          currentQuestionIndex === 8
            ? "md:flex-col"
            : ""
        } relative ${
          currentQuestionIndex === 3 ||
          currentQuestionIndex === 4 ||
          currentQuestionIndex === 6
            ? "flex-row-reverse"
            : ""
        }`}
      >
        {/* <div
          className={`text-[90px] p-6 absolute left-3 ${
            currentQuestionIndex === 4 ? "bottom-0" : ""
          }`}
        >
          Q{currentQuestionIndex + 1}
        </div> */}

        <motion.div
          key={currentQuestionIndex}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 50 }}
          transition={{ duration: 0.5 }}
          className={`flex ${
            currentQuestionIndex === 3 || currentQuestionIndex === 4
              ? "justify-end items-end"
              : ""
          }`}
        >
          <div
            className={`h-screen flex flex-col justify-center px-28 w-[90%] md:w-full ${
              currentQuestionIndex === 5 ||
              currentQuestionIndex === 6 ||
              currentQuestionIndex === 8
                ? "md:h-auto"
                : ""
            } ${
              currentQuestionIndex === 8 || currentQuestionIndex === 0
                ? "lg:w-full"
                : ""
            }  1xl:px-10 ${
              currentQuestionIndex === 2 || currentQuestionIndex === 4
                ? "lg:justify-end pb-10"
                : currentQuestionIndex === 8 ||
                  currentQuestionIndex === 0 ||
                  currentQuestionIndex === 7
                ? "lg:justify-center"
                : "lg:justify-start"
            }  lg:pt-10 ${
              currentQuestionIndex === 5 ||
              currentQuestionIndex === 6 ||
              currentQuestionIndex === 8
                ? "xl:w-[80%]"
                : "xl:w-[65%]"
            }`}
          >
            {currentQuestionIndex !== 0 && (
              <button
                onClick={handleBack}
                className="text-[#898884] py-2 transition flex items-center gap-2"
                disabled={currentQuestionIndex === 0}
              >
                <IoArrowBackOutline /> Back
              </button>
            )}
            <h2 className="text-lg font-semibold mb-4 max-w-[700px]">
              {currentQuestionIndex === 0
                ? "What can we call you?"
                : currentQuestionIndex === 1
                ? `Hi ${name}! ${questions[currentQuestionIndex]?.question}`
                : questions[currentQuestionIndex]?.question}{" "}
            </h2>

            {currentQuestion.type === "input" ? (
              <div>
                {currentQuestionIndex === 0 && (
                  <input
                    type="text"
                    value={name}
                    onChange={handleNameChange}
                    placeholder={currentQuestion.placeholder}
                    className="rounded-lg p-2 w-fit mb-4 py-3 outline-none border border-gray-300 min-w-[350px] xs:min-w-[280px]"
                  />
                )}
                {currentQuestionIndex === 7 && (
                  <textarea
                    value={caregivingMoment}
                    onChange={handleCaregivingMomentChange}
                    placeholder={currentQuestion.placeholder}
                    className="rounded-lg p-2 w-fit mb-4 py-3 outline-none border border-gray-300 min-w-[350px] xs:min-w-full"
                  />
                )}
                {currentQuestionIndex === 8 && (
                  <input
                    type="email"
                    value={emailInput}
                    onChange={handleEmailChange}
                    placeholder={currentQuestion.placeholder}
                    className="rounded-lg p-2 w-fit mb-4 py-3 outline-none border border-gray-300 min-w-[350px] xs:min-w-full"
                  />
                )}
              </div>
            ) : (
              <div className="flex flex-col mb-4 w-fit">
                {currentQuestion.options.map((option, index) => (
                  <label
                    key={option.value}
                    className="mb-3 flex justify-between gap-4 items-center"
                  >
                    <input
                      type={
                        currentQuestion.type === "checkbox"
                          ? "checkbox"
                          : "radio"
                      }
                      value={option?.value}
                      checked={
                        currentQuestion.type === "checkbox"
                          ? Array.isArray(
                              answers[currentQuestionIndex].answer
                            ) &&
                            answers[currentQuestionIndex].answer.includes(
                              option?.label
                            )
                          : answers[currentQuestionIndex].answer ===
                            option?.label
                      }
                      onChange={() =>
                        handleOptionChange(option?.value, option.id)
                      }
                      className="hidden"
                    />
                    <div
                      className={`cursor-pointer p-2 border-2 rounded-lg ${
                        currentQuestion.type === "checkbox" &&
                        answers[currentQuestionIndex].answer.includes(
                          option?.label
                        )
                          ? "border-[#DD3E77] bg-[#FEE4E7]"
                          : currentQuestion.type === "radio" &&
                            answers[currentQuestionIndex].answer[0] ===
                              option?.label
                          ? "border-[#DD3E77] bg-[#FEE4E7]"
                          : "border-gray-400 bg-white"
                      }`}
                    >
                      <span>{`${option?.label}`}</span>
                      {/* <span>{`${letterPrefix[index]}: ${option?.label}`}</span> */}
                    </div>
                  </label>
                ))}
              </div>
            )}

            {errorMessage && (
              <p className="text-red-600 mb-4 text-sm">{errorMessage}</p>
            )}

            <div onClick={handleNext}>
              <Button
                title={
                  currentQuestionIndex < questions.length - 1
                    ? "Next"
                    : "Submit"
                }
              />
            </div>
          </div>
        </motion.div>

        <div
          className={`h-screen flex ${
            currentQuestionIndex === 2
              ? "justify-start items-start"
              : currentQuestionIndex === 8
              ? "justify-center items-center"
              : "justify-end items-end"
          } `}
        >
          {[
            <Que1Logo id={selectedIds} />,
            <Que2Logo id={selectedIds} />,
            <Que3Logo id={selectedIds} />,
            <Que4Logo id={selectedIds} />,
            <Que5Logo id={selectedIds} />,
            <Que6Logo id={selectedIds} />,
            "",
            <div className="pr-24 xl:pr-10 md:pr-0">
              <MainLogo id={selectedIds} />
            </div>,
          ][currentQuestionIndex - 1] || null}
        </div>
      </div>
    </div>
  );
};

export default QuestionForm;
