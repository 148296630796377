import React from 'react'

const M22 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147"
        height="147"
        viewBox="0 0 147 147"
        fill="none"
      >
        <path
          d="M69.5423 80.8443C69.5423 80.279 69.5423 79.7137 68.977 78.583C68.4116 78.583 67.2808 78.583 66.7154 78.0176C22.6154 71.2335 0.565385 45.2276 0 6.10352e-05H27.7039C28.2692 29.9633 39.5769 45.793 66.7154 50.8811H67.2808C75.1962 52.5771 94.4193 52.5771 94.4193 52.5771C94.4193 52.5771 94.4192 71.7988 96.1154 79.7137V80.279C101.204 107.416 116.469 118.722 147 119.288V146.99C102.335 147.555 76.3269 124.941 69.5423 80.8443Z"
          fill="white"
        />
        <path
          d="M69.5423 80.8443C69.5423 80.279 69.5423 79.7137 68.977 78.583C68.4116 78.583 67.2808 78.583 66.7154 78.0176C22.6154 71.2335 0.565385 45.2276 0 6.10352e-05H27.7039C28.2692 29.9633 39.5769 45.793 66.7154 50.8811H67.2808C75.1962 52.5771 94.4193 52.5771 94.4193 52.5771C94.4193 52.5771 94.4192 71.7988 96.1154 79.7137V80.279C101.204 107.416 116.469 118.722 147 119.288V146.99C102.335 147.555 76.3269 124.941 69.5423 80.8443Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M22
