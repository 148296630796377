import React from "react";

const Q4o1 = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path d="M80 80V3.05176e-05H52.766V52.9578H0V80H80Z" fill="#FAA31B" />
      </svg>
    </div>
  );
};

export default Q4o1;
