import React, { useRef } from "react";
import Button from "./ui/Button";
import MainLogo from "./MainLogo";
import { useLocation } from "react-router-dom";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from "react-share";
import html2canvas from "html2canvas";
import { SuccessToast } from "../helper/Toast";

const SubmitPage = () => {
  const location = useLocation();
  const logoRef = useRef();

  const shareUrl = window.location.href;
  const title = "Check out my submission!";

  const downloadLogo = () => {
    if (logoRef.current) {
      html2canvas(logoRef.current).then((canvas) => {
        const link = document.createElement("a");
        link.href = canvas.toDataURL("image/png");
        link.download = "logo.png";
        link.click();
      });
    }
  };

  const downloadImageAndShare = () => {
    if (logoRef.current) {
      html2canvas(logoRef.current).then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = "logo-image.png";
            link.click();
            SuccessToast("Image downloaded. You can now share it!");
          }
        });
      });
    }
  };

  return (
    <div>
      <div className="flex justify-end me-5" onClick={downloadLogo}>
        <Button title="Download" />
      </div>
      <div className="p-10 flex flex-col items-center justify-center gap-5 mt-20">
        <div ref={logoRef} className="p-10">
          <MainLogo id={location?.state?.selectedIds} />
        </div>

        <div className="flex justify-center gap-4 mt-10">
          <div onClick={downloadImageAndShare}>
            <WhatsappShareButton url={shareUrl} title={title}>
              <WhatsappIcon size={32} round />
            </WhatsappShareButton>
          </div>

          <div onClick={downloadImageAndShare}>
            <TwitterShareButton url={shareUrl} title={title}>
              <TwitterIcon size={32} round />
            </TwitterShareButton>
          </div>

          <div onClick={downloadImageAndShare}>
            <FacebookShareButton url={shareUrl} quote={title}>
              <FacebookIcon size={32} round />
            </FacebookShareButton>
          </div>
        </div>

        <a
          href="https://noorahealth.org/"
          target="_blank"
          rel="noreferrer"
          className="flex justify-center"
        >
          <Button title="Noora website" />
        </a>
      </div>
    </div>
  );
};

export default SubmitPage;
