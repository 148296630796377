import React from 'react'

const Q2o3 = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path
          d="M0 80.0001V6.10352e-05H27.234V52.9578H80V80.0001H0Z"
          fill="#00B9EA"
        />
      </svg>
    </div>
  );
}

export default Q2o3
