import React from "react";

const Button = ({ title }) => {
  return (
    <div>
      <button className="mt-5 bg-[#DD3E77] p-3 px-8 rounded-full outline-none text-white font-semibold">
        {title}
      </button>
    </div>
  );
};

export default Button;
