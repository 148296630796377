import React from "react";

const M41 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
      >
        <path d="M80 80V3.05176e-05H52.766V52.9578H0V80H80Z" fill="white" />
        <path
          d="M80 80V3.05176e-05H52.766V52.9578H0V80H80Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M41;
