import React from "react";

const M21 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M28.9362 80.2847C34.0426 107.423 49.3617 118.731 80 119.296L80 147C34.6099 146.435 8.51065 123.819 1.70214 80.2847C0.567384 72.9347 9.19351e-06 52.5808 9.19351e-06 52.5808L8.89695e-06 50.8847L0 6.10352e-05L27.234 5.62734e-05L27.234 52.5808C27.234 52.5808 27.2341 72.9347 28.9362 80.2847Z"
          fill="white"
        />
        <path
          d="M28.9362 80.2847C34.0426 107.423 49.3617 118.731 80 119.296L80 147C34.6099 146.435 8.51065 123.819 1.70214 80.2847C0.567384 72.9347 9.19351e-06 52.5808 9.19351e-06 52.5808L8.89695e-06 50.8847L0 6.10352e-05L27.234 5.62734e-05L27.234 52.5808C27.234 52.5808 27.2341 72.9347 28.9362 80.2847Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M21;
