import React from "react";

const Q12 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="512"
        // height="509"
        className="w-[512px] h-[509px] lg:w-[234px] lg:h-[234px]"
        viewBox="0 0 512 509"
        fill="none"
      >
        <path
          d="M228.863 241.038C230.819 241.038 232.774 241.038 236.686 239.082C236.686 237.127 236.686 233.215 238.642 231.259C262.111 78.6965 355.537 2.41541 512 0.459495L512 96.2999C408.343 98.2558 350.122 137.374 332.52 231.259L332.52 233.215C326.652 260.598 326.652 327.099 326.652 327.099C326.652 327.099 260.156 327.099 232.775 332.967L230.819 332.967C136.941 350.57 97.8252 403.38 95.8694 509L0.035765 509C-1.92002 354.482 76.3116 264.509 228.863 241.038Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q12;
