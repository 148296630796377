import React from "react";

const Q4o2 = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="147"
        viewBox="0 0 80 147"
        fill="none"
      >
        <path
          d="M51.0638 80.2846C45.9574 107.423 30.6383 118.731 2.02978e-06 119.296L-2.81412e-06 147C45.3901 146.435 71.4893 123.819 78.2979 80.2847C79.4326 72.9347 80 52.5808 80 52.5808L80 50.8847L80 4.57764e-05L52.766 4.10146e-05L52.766 52.5808C52.766 52.5808 52.7659 72.9346 51.0638 80.2846Z"
          fill="#FAA31B"
        />
      </svg>
    </div>
  );
};

export default Q4o2;
