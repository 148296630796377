import React from "react";

const Q14 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="239"
        // height="278"
        className="w-[239px] h-[278px] lg:w-[125px] lg:h-[122px]"
        viewBox="0 0 239 278"
        fill="none"
      >
        <path
          d="M238.6 0.8C238.7 0.5 238.9 0.3 239 0V95.5C236.7 95.8 234.7 96.2 232.8 96.6H230.8C136.9 114.2 98 174.3 96 278H0C2 121.5 78.2 26.2 230.8 2.7C232.8 0.8 236.7 0.8 238.6 0.8Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q14;
