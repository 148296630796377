import React from "react";

const Q32 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="277"
        // height="516"
        className="w-[277px] h-[516px] lg:w-[127px] lg:h-[234px]"
        viewBox="0 0 277 516"
        fill="none"
      >
        <path
          d="M176.808 234.185C159.128 138.923 106.085 99.2308 1.83355e-05 97.2462L1.33242e-06 -1.2603e-05C157.163 1.98458 247.532 81.3692 271.106 234.185C275.035 259.985 277 331.431 277 331.431L277 337.385L277 516L182.702 516L182.702 331.431C182.702 331.431 182.702 259.985 176.808 234.185Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q32;
