import React from "react";

const Q33 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="239"
        // height="278"
        className="w-[239px] h-[278px] lg:w-[125px] lg:h-[126px]"
        viewBox="0 0 239 278"
        fill="none"
      >
        <path
          d="M0.399994 0.8C0.299988 0.5 0.100006 0.3 0 0V95.5C2.3 95.8 4.3 96.2 6.2 96.6H8.2C102.1 114.2 141 174.3 143 278H239C237 121.5 160.8 26.2 8.2 2.7C6.2 0.8 2.29999 0.8 0.399994 0.8Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q33;
