import React from 'react'

const Q3o3 = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="78"
        viewBox="0 0 69 78"
        fill="none"
      >
        <path
          d="M68 78H40C42.8 38.4 14.5 27.5 -2.28882e-05 27V0C58.8 4.8 69.8333 54 68 78Z"
          fill="#00986B"
        />
      </svg>
    </div>
  );
}

export default Q3o3
