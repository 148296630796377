import React from "react";

const Q43 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="516"
        height="277"
        className="w-[516px] h-[277px] lg:h-[127px] lg:w-[234px]"
        viewBox="0 0 516 277"
        fill="none"
      >
        <path
          d="M281.815 176.809C377.077 159.128 416.769 106.085 418.754 0.000109857L516 0.000114108C514.015 157.163 434.631 247.532 281.815 271.106C256.015 275.035 184.569 277 184.569 277L178.615 277L0 277L4.12189e-06 182.702L184.569 182.702C184.569 182.702 256.015 182.702 281.815 176.809Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q43;
