import React from 'react'

const TopLeft = () => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="160"
        height="160"
        viewBox="0 0 160 160"
        fill="none"
      >
        <path
          opacity="0.16"
          d="M84.313 87.9938C84.313 87.3784 84.313 86.7631 84.9283 85.5324C85.5437 85.5324 86.7744 85.5324 87.3897 84.9171C135.386 77.533 159.385 49.2273 160 0H129.848C129.233 32.6131 116.926 49.8426 87.3897 55.3807H86.7744C78.1596 57.2267 57.238 57.2267 57.238 57.2267C57.238 57.2267 57.238 78.1483 55.392 86.7631V87.3784C49.8539 116.915 33.2397 129.222 0.011261 129.837V159.989C48.6232 160.604 76.9289 135.99 84.313 87.9938Z"
          fill="#ED2777"
        />
      </svg>
    </div>
  );
}

export default TopLeft
