import React from "react";

const Que6Logo = ({ id }) => {
  // const getFillColor = (index) =>
  //   id[6] === `Dg${index}` ? "#383838" : "#F5F5F5";

  const getFillColor = (index) =>
    id[6]?.includes(`Dg${index}`) ? "#383838" : "#F5F5F5";

  const icons = [1, 2, 3, 4];

  return (
    <div>
      <div className="relative mt-5 flex flex-col gap-20 pb-28 pl-20 w-[500px] xl:w-[360px] xl:pl-10 xl:pb-10 md:pr-10">
        {icons.map((icon, idx) => (
          <div
            key={`icon-${icon}`}
            className={`${idx === 1 ? "flex justify-center items-center" : ""} 
                       ${idx === 3 ? "flex justify-end items-end" : ""}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="50"
              height="50"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M14.8957 7.75459C14.9054 9.13712 14.5037 10.4913 13.7416 11.6449C12.9796 12.7985 11.8916 13.6993 10.6162 14.2329C9.3407 14.7665 7.93536 14.9086 6.57889 14.6413C5.22241 14.3739 3.97611 13.7092 2.99848 12.7316C2.02086 11.7539 1.3561 10.5076 1.08876 9.15115C0.821411 7.79467 0.963557 6.38933 1.49712 5.11387C2.03069 3.83841 2.93155 2.75046 4.08514 1.98841C5.23873 1.22636 6.59292 0.824637 7.97545 0.834339C9.81081 0.834339 11.571 1.56343 12.8688 2.86123C14.1666 4.15903 14.8957 5.91922 14.8957 7.75459Z"
                fill={getFillColor(icon)}
              />
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Que6Logo;
