import React from "react";
import TopLeft from "../assets/svg/BgSvgs/TopLeft";
import TopRight from "../assets/svg/BgSvgs/TopRight";
import BottomLeft from "../assets/svg/BgSvgs/BottomLeft";
import BottomRight from "../assets/svg/BgSvgs/BottomRight";

const PrimaryLayout = ({ children }) => {
  return (
    <div className="h-screen relative">
      <div>
        <div className="absolute left-0">
          <TopLeft />
        </div>
        <div className="absolute right-0">
          <TopRight />
        </div>
      </div>
      {children}
      <div>
        <div className="absolute left-0 bottom-0">
          <BottomLeft />
        </div>
        <div className="absolute right-0 bottom-0">
          <BottomRight />
        </div>
      </div>
    </div>
  );
};

export default PrimaryLayout;
