import React from "react";
import { Link } from "react-router-dom";
import Button from "./ui/Button";
import IntroLogo from "../assets/svg/IntroLogo";
import PrimaryLayout from "../layout/PrimaryLayout";

const IntroPage = () => {
  return (
    <PrimaryLayout>
      <div className="p-10 flex items-center justify-center gap-20 h-screen lg:overflow-y-auto">
        <div className="flex items-center justify-center gap-20 w-[80%] 1xl:w-full lg:flex-col-reverse lg:py-10 lg:gap-6">
          <div className="w-3/4 1xl:pr-10 lg:pr-0 lg:w-full">
            <h1 className="font-playfair text-[40px] md:text-[30px] mb-5 font-medium lg:text-center">
              What is your caregiving style?
            </h1>
            <div className="font-poppins lg:text-center">
              <p>
                Caregiving is the crux of all we do at Noora Health. Over the
                last decade, we have explored many facets of caregiving,
                designing and implementing programs and strategies that further
                help us comprehend what caregiving can look like in different
                situations. We need more voices in this broadening dialogue to
                help us in our mission to make caregiver support a global
                standard of care.
              </p>
              <p className="mt-2">
                Through this experience we would help you understand what kind
                of a caregiver you are or likely would be that will be
                represented by a tile, unique to your style. This unique
                identity will plug itself within this growing community of
                caregivers. We are all bound together by this regardless of age,
                condition, geography/race and gender. Because Who cares? We all
                do and we would love to hear your story.
              </p>
              <Link to="/form">
                <Button title="Lets find out!" />
              </Link>
            </div>
          </div>
          <div className="w-1/4 flex justify-center 1xl:pr-20 lg:pr-0 lg:mt-20 xs:mt-40 xxs:mt-72">
            <IntroLogo />
          </div>
        </div>
      </div>
    </PrimaryLayout>
  );
};

export default IntroPage;
