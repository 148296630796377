import React from "react";

const Q31 = ({ color, SelectedColor, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        // width="277"
        // height="277"
        className="w-[277px] h-[277px] lg:w-[127px] lg:h-[127px]"
        viewBox="0 0 277 277"
        fill="none"
      >
        <path
          d="M277 3.45946V276.757H182.785V91.6757L0.243164 93.5517V0L277 3.45946Z"
          fill={!flag ? color : SelectedColor}
        />
      </svg>
    </div>
  );
};

export default Q31;
