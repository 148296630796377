import React from 'react'

const M33 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="69"
        height="78"
        viewBox="0 0 69 78"
        fill="none"
      >
        <path
          d="M68 78H40C42.8 38.4 14.5 27.5 -2.28882e-05 27V0C58.8 4.8 69.8333 54 68 78Z"
          fill="white"
        />
        <path
          d="M68 78H40C42.8 38.4 14.5 27.5 -2.28882e-05 27V0C58.8 4.8 69.8333 54 68 78Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M33
