import React from 'react'

const M43 = ({ color, flag = false }) => {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="147"
        height="81"
        viewBox="0 0 147 81"
        fill="none"
      >
        <path
          d="M80.2846 51.5639C107.423 46.4575 118.731 31.1383 119.296 0.500055L147 0.500055C146.435 45.8901 123.819 71.9894 80.2846 78.7979C72.9346 79.9327 52.5808 80.5 52.5808 80.5L50.8846 80.5L0 80.5L3.24763e-07 53.266L52.5808 53.266C52.5808 53.266 72.9346 53.266 80.2846 51.5639Z"
          fill="white"
        />
        <path
          d="M80.2846 51.5639C107.423 46.4575 118.731 31.1383 119.296 0.500055L147 0.500055C146.435 45.8901 123.819 71.9894 80.2846 78.7979C72.9346 79.9327 52.5808 80.5 52.5808 80.5L50.8846 80.5L0 80.5L3.24763e-07 53.266L52.5808 53.266C52.5808 53.266 72.9346 53.266 80.2846 51.5639Z"
          fill={color}
          fill-opacity={!flag ? "0.16" : ""}
        />
      </svg>
    </div>
  );
};

export default M43
